body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.page-section .section-content {
    position: relative;
    z-index: 3;
}
.textaligncenter {
    text-align: center;
}
h5 {
    color: #222;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
.row {
    margin-left: -15px;
    margin-right: -15px;
}
.md-box-icon.align-left.advanced-icon .md-icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 26px;
}
h4 {
    color: #222;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.md-cta .cta-content {
    
    width: 75%;
    display: inline-block;
}
.md-cta .cta-button {
    
    padding: 35px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 25%;
    text-align: center;
}
.page-section .shadow-inside-bottom {
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.15);
}

/**************/


.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
/* left side contact form */
.contact-form {
  padding: 15px;
  text-align: left;
  /*margin-bottom: 50px;*/
}

.submit-btn {
  background-color: #333333;
  border: none;
  padding: 8px 20px;
  text-transform: uppercase;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
}

.submit-btn:hover {
  background-color: #06a0c3;
}

/* right side contact form */
.contact-label {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  padding-left: 5px;
}

.contact-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}

.contact-desc {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
header .header-top {
  background: #333333;
  color: #fff;
  font-family: Source Sans Pro, sans-serif;
  font-size: 12px;
  font-weight: 700;
  display: block;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  border-bottom: 1px solid #e5e5e5;
}

header .header-top .header-slogan {
  float: left;
}

header .header-top .header-social {
  text-align: right;
  width: auto;
  margin: 0;
  padding: 0;
}

.social-links {
  display: inline-block;
  vertical-align: top;
}

a {
  -webkit-transition: all .2s;
  transition: all .2s;
}

header .header-top .header-social a {
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  font-size: 14px;
}

header .header-top .header-social a {
  color: #fff;
}

/* This is where you put the icon hover color */

.facebook:hover {
  background-color:rgb(55, 0, 255);

}

.linkedin:hover {
  background-color: rgb(55, 0, 255);
}

.twitter:hover {
  background-color: rgb(55, 0, 255);
}

.youtube:hover {
  background-color: rgb(55, 0, 255);
}
.menu-link {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  
}

.menu-link.active,
.menu-link:hover {
  color: rgb(55, 0, 255);
  text-decoration: none;
  text-decoration: none;
}

.header-container {
  display: flex;
  flex-direction: row;
}

.header-container > div {
  margin: auto 7px auto 7px;
}

.header-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.header-link-underline {
  width: 0;
  height: 1px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.menu-link:hover + .header-link-underline {
  height: 1px;
  max-height: 1px;
  width: 55%;
  border-bottom: 1px solid red;
}

@media (max-width: 767px) {
  .header-link-text {
    font-size: 0.8em;
  }
}

.widget-title {
  font-family: Source Sans Pro, sans-serif;
  font-weight: 700;
}

footer {
  background: #333333;
  width: 100%;
  padding: 60px 0 0 0;
  font-size: 13px;
  display: block;
}

footer .widget-title {
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  border: none;
}

footer .widget .textwidget {
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  background: transparent;
  box-shadow: none;
  font-family: "lora", serif;
  text-align: center;
}

.textwidget > p {
  margin-bottom: 5px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.widget-title {
  margin: 0 0 15px 0;
  padding: 0;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2px;
}

footer .column .widget:last-child {
  margin-bottom: 0;
}

.widget {
  margin-bottom: 40px;
  width: 100%;
}

.page-link {
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  text-align: left;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.page-link:hover {
  color: rgb(55, 0, 255);
  background-color: transparent;
}

.page_item {
  list-style: none;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.page-section .section-content {
    position: relative;
    z-index: 3;
}
.textaligncenter {
    text-align: center;
}
h5 {
    color: #222;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
.row {
    margin-left: -15px;
    margin-right: -15px;
}
.md-box-icon.align-left.advanced-icon .md-icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 26px;
}
h4 {
    color: #222;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.md-cta .cta-content {
    
    width: 75%;
    display: inline-block;
}
.md-cta .cta-button {
    
    padding: 35px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 25%;
    text-align: center;
}
.page-section .shadow-inside-bottom {
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.15);
}

/**************/


.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}
