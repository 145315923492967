/* left side contact form */
.contact-form {
  padding: 15px;
  text-align: left;
  /*margin-bottom: 50px;*/
}

.submit-btn {
  background-color: #333333;
  border: none;
  padding: 8px 20px;
  text-transform: uppercase;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
}

.submit-btn:hover {
  background-color: #06a0c3;
}

/* right side contact form */
.contact-label {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  padding-left: 5px;
}

.contact-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}

.contact-desc {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}