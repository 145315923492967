.why-container {
  width: 100%;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), inset 0 -1px 3px rgba(0, 0, 0, 0.15); */
}

.why-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 80px;
  margin-bottom: 100px;
  width: -webkit-fill-available;
}

.section-mask {
  background-color:rgba(255, 255, 255, 0.877);
  height: 100%;
}

.options {
  margin-right: 34px;
  text-align: right;
}

.icon {
  color: white;
  opacity: 0.7;
}

.quick-response {
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.subtext {
  font-weight: 400;
  font-size: 16px;
}

.option1 {
  margin-right: 33px;
  margin-bottom: 50px;
  margin-top: 45px;
}

.option2 {
  margin-right: 20px;
  margin-bottom: 50px;
}

.option3 {
  margin-right: 23px;
  margin-bottom: 50px;
}

.icon-mobile {
  margin-top: 48px;
  margin-right: 10px;
}

.icon-gear {
  margin-right: 4px;
}

.icon-dollar {

}