.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.page-section .section-content {
    position: relative;
    z-index: 3;
}
.textaligncenter {
    text-align: center;
}
h5 {
    color: #222;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
.row {
    margin-left: -15px;
    margin-right: -15px;
}
.md-box-icon.align-left.advanced-icon .md-icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 26px;
}
h4 {
    color: #222;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.md-cta .cta-content {
    
    width: 75%;
    display: inline-block;
}
.md-cta .cta-button {
    
    padding: 35px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 25%;
    text-align: center;
}
.page-section .shadow-inside-bottom {
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.15);
}

/**************/

