.menu-link {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  
}

.menu-link.active,
.menu-link:hover {
  color: rgb(55, 0, 255);
  text-decoration: none;
  text-decoration: none;
}

.header-container {
  display: flex;
  flex-direction: row;
}

.header-container > div {
  margin: auto 7px auto 7px;
}

.header-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.header-link-underline {
  width: 0;
  height: 1px;
  transition: all 0.1s ease-in-out;
}

.menu-link:hover + .header-link-underline {
  height: 1px;
  max-height: 1px;
  width: 55%;
  border-bottom: 1px solid red;
}

@media (max-width: 767px) {
  .header-link-text {
    font-size: 0.8em;
  }
}
